<template>
  <div class="container mt-3">
    <div class="d-flex mb-3">
      <button class="btn btn-dark px-5 me-3 text-nowrap" @click.prevent="generateReport"
              :disabled="selectedTickets.length === 0 || state.reportLoading">
        Сформировать реестр
      </button>
      <div class="spinner-border spinner-border text-dark" role="status" v-if="state.reportLoading">
        <span class="visually-hidden">Loading...</span>
      </div>
      <a v-if="state.reportUrl" :href="state.reportUrl" class="d-flex align-content-center pointer"
         :download="filename()">

        <p-icon name="file_download"/>
        <span class="ms-2 text-decoration-underline">
          Скачать
        </span>
      </a>
      <div class="input-group w-25 mb-auto" v-if="false">
        <p-icon class="input-group-text" name="search"/>
        <input type="text" class="form-control" placeholder="Поиск">
      </div>
    </div>
    <div class="d-flex mb-3">
      <div class="input-group w-25 mb-auto">
        <p-icon class="input-group-text" name="search"/>
        <p-datetime-picker range type="text" placeholder="Поиск" v-model:value="state.dateRange"/>
      </div>
    </div>
    <p-table :data="ticketsSelected" :columns="ticketsColumns">
      <template v-slot:header-index>
        <input class="form-check-input" type="checkbox" :checked="allTicketsSelected" @click="toggleAllTickets">
      </template>

      <template v-slot:col-index="{ row }">
        <input class="form-check-input" type="checkbox" v-model="state.ticketsSelected[row.id]">
      </template>

      <template v-slot:col-open="{ row }" class="text-center">
        <router-link
          :to="{ name: 'ticket', params: { id: row.id } }"
        >
          <p-icon class="text-center w-100" name="launch"/>
        </router-link>
      </template>
    </p-table>
  </div>
</template>

<script setup>
import api from '@/api'
import urls from '@/api/urls'
import PTable from '@/components/ui/Table'
import { computed, defineProps, onMounted, reactive, watch } from 'vue'
import { serializeDate } from '@/common'

const props = defineProps({
  ticketsColumns: {
    type: Object,
    required: true,
  },
  filter: {
    type: Function,
    required: true,
  },
})

const state = reactive({
  tickets: [],
  ticketsSelected: {},
  reportLoading: false,
  reportUrl: null,
  allTicketsSelected: false,
  dateRange: null,
})

const ticketsSelected = computed(() => {
  if (state.dateRange === null) {
    return state.tickets
  }
  const [rangeStart, rangeEnd] = state.dateRange
  return state.tickets.filter(ticket => {
    const date = new Date(ticket.date)
    const from = new Date(serializeDate(rangeStart))
    const to = new Date(serializeDate(rangeEnd))
    return date >= from && date <= to
  })
})
const allTicketsSelected = computed(() => Boolean(selectedTickets.value.length === ticketsSelected.value.length))

const toggleAllTickets = () => {
  if (allTicketsSelected.value) {
    state.ticketsSelected = {}
  } else {
    ticketsSelected.value.forEach(ticket => {
      state.ticketsSelected[ticket.id] = true
    })
  }
}

const selectedTickets = computed(() =>
  ticketsSelected.value.filter(ticket => state.ticketsSelected[ticket.id]).map(ticket => ticket.id)
)

const generateReport = async () => {
  try {
    state.reportLoading = true
    const { url } = await api.postForFile(urls.TICKETS.REPORT, selectedTickets.value)
    state.reportUrl = url
    await load()
  } finally {
    state.reportLoading = false
  }
}

const filename = () => {
  const today = new Date()
  return `Реестр ${today.toLocaleDateString()}.xlsx`
}

const load = async () => {
  state.tickets = (await api.post(urls.TICKETS.LIST, ['done'])).reverse().filter(props.filter)
}
onMounted(load)
</script>

<style scoped>

</style>
