<template>
  <p-report-generator :tickets-columns="ticketsColumns" :filter="() => true"/>
</template>

<script setup>
import PReportGenerator from '@/components/ui/ReportGenerator'
import { formatAddress, formatDate, formatName } from '@/common'

const ticketsColumns = [
  {
    name: 'Номер',
    key: 'index',
  },
  {
    name: 'Адрес',
    key: 'address',
    getter: formatAddress,
  },
  {
    name: 'Заказчик',
    key: 'customer',
    getter: formatName,
  },
  {
    name: 'Работа',
    key: 'workType',
  },
  {
    name: 'УК',
    key: 'managerName',
  },
  {
    name: 'Исполнитель',
    key: 'worker',
    getter (row) {
      return row.worker ? formatName(row.worker) : 'не назначен'
    },
  },
  {
    name: 'Дата',
    key: 'date',
    getter: (row) => formatDate(row.date),
  },
  {
    name: 'Просмотр',
    key: 'open',
  },
]
</script>

<style scoped>

</style>
